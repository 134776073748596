import styled from 'styled-components';

export const ForgetPasswordFormContainer = styled.div`
    display: flex;
    height: 100vh;
    justify-content: center;
    align-items: center;

    .image-container {
        width: 400px;
        margin-left: 12%;
        @media (max-width: 1244px) {
            display: none;
        }
    }

    .form-container {
        padding: 0 30px;
    }

    .title {
        font-size: 30px;
        font-weight: bold;
        margin: 20px 0 5px 0;
        @media (max-width: 1300px) {
            font-size: 20px;
        }
    }

    .description {
        font-size: 18px;
        text-align: justify;
        margin: 0 0 30px 0;
        opacity: 0.6;
        //@media (max-width: 1300px) {
        //    font-size: 14px;
        //}
    }

    .input {
        max-width: 500px;
    }
`;
