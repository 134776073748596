import React, {useEffect, useState} from 'react'
import Image from '../../common/components/Image'
import ForgetPasswordImage from '../../common/assets/image/forget-password.png'
import {ForgetPasswordFormContainer} from './ForgetPasswordForm.style'
import axios from 'axios'
import {ErrorMessage, Formik} from 'formik'
import * as Yup from 'yup'
import {__} from '../../common/utils/LanguageHelper'
import Input from '../../common/components/Input'
import FullButton from '../FullButton'
import {Link, navigate} from 'gatsby-plugin-intl'
import LogoImage from '../../common/assets/image/logo.png'
import withReactContent from "sweetalert2-react-content"
import Swal from "sweetalert2"

const ForgetPasswordForm = () => {
  const MySwal = withReactContent(Swal)
  const [serverErrors, setServerErrors] = useState([])
  const [tenantId, setTenantId] = useState('')

  useEffect(() => {
    setTenantId(localStorage.getItem('tenant_id'))
  }, [])

  const ForgetPasswordSchema = Yup.object().shape({
    email: Yup.string()
      .email(
        __(
          'ادخل بريد الكتروني صالح مثال: you@example.com',
          'Enter valid email EX: you@example.com'
        )
      )
      .required(__('ادخل البريد الإلكتروني', 'Enter your email')),
  })

  const errorMessage = {
    position: 'center',
    icon: 'error',
    title: __('خطأ', 'Error'),
    html: __('البريد الالكتروني غير صحيح', 'Email not valid'),
  }

  const successMessage = {
    position: 'center',
    icon: 'success',
    title: __('تم ارسال بريد الكتروني بالتعليمات اللازمة لاعادة اعداد كلمة المرور', 'An email with instructions how to reset your password sent to you.')
  }

  return (
    <ForgetPasswordFormContainer>
      <div className="image-container">
        <Image
          src={ForgetPasswordImage}
          alt="Forget password"
          className="forget-password-image"
        />
      </div>

      <div className="form-container">
        <Link to="/">
          <Image src={LogoImage} alt="Awasul logo"/>
        </Link>

        <div>
          <h1 className="title">
            {__('إعادة تعيين كلمة المرور', 'Password Reset')}
          </h1>
          <p className="description">
            {__(
              'سيصلك بريد الكتروني بالتعليمات اللازمة لاعادة اعداد كلمة المرور',
              'You will receive an email with instructions how to reset your password'
            )}
          </p>
        </div>

        <Formik
          initialValues={{
            email: '',
          }}
          validationSchema={ForgetPasswordSchema}
          onSubmit={async (values, {setSubmitting}) => {
            const res = await axios.post(
              `https://api.awasul1.com/v1/forget-password`,
              {
                email: values.email,
                tenant_id: tenantId,
              }
            )

            setSubmitting(false)

            const data = res.data
            if (data.error) {
              MySwal.fire(errorMessage).finally()

              return
            }

            MySwal.fire(successMessage).finally()

            return navigate('/')
          }}
        >
          {({
              values,
              errors,
              touched,
              handleSubmit,
              handleChange,
              isSubmitting,
            }) => (
            <>
              <Input
                inputType="email"
                name="email"
                label={__('البريد الإلكتروني', 'Email Address')}
                placeholder="you@example.com"
                value={values.email}
                onChange={(val) => {
                  handleChange(val)
                }}
                errorMessage={
                  <>
                    <ErrorMessage
                      name="username"
                      component="div"
                      className="error-message"
                    />
                    {serverErrors.email && (
                      <p className="error-message">
                        {serverErrors.email}
                      </p>
                    )}
                  </>
                }
                className={`input ${
                  (serverErrors.email || errors.email) &&
                  touched.email &&
                  'input-error'
                }`}
              />
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                }}
              >
                <FullButton
                  disabled={isSubmitting}
                  type="submit"
                  noShadow
                  content={
                    isSubmitting
                      ? __(
                      'جاري الارسال...',
                      'Sending...'
                      )
                      : __(
                      'إعادة التعيين',
                      'Reset password'
                      )
                  }
                  onClick={() => handleSubmit()}
                />
                <Link to="/login">
                  <FullButton
                    disabled={isSubmitting}
                    type="submit"
                    content={__('إلغاء', 'Cancel')}
                    variant="outline"
                    color="rgb(172 170 170)"
                  />
                </Link>
              </div>
            </>
          )}
        </Formik>
      </div>
    </ForgetPasswordFormContainer>
  )
}

export default ForgetPasswordForm
