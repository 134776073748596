import React from 'react';
import { __ } from '../common/utils/LanguageHelper';
import { ThemeProvider } from 'styled-components';
import { saasTheme } from '../common/theme/saas';
import { GlobalStyle } from '../containers/saas.style';
import SEO from '../components/seo';
import { ResetCSS } from '../common/assets/css/style';
import { useIntl } from 'gatsby-plugin-intl';
import ForgetPasswordForm from '../containers/ForgetPasswordForm';

export default () => {
    const { locale } = useIntl();

    return (
        <ThemeProvider theme={saasTheme}>
            <SEO title={__('نسيت كلمة المرور', 'Forget Password')} />
            <ResetCSS />
            <GlobalStyle />
            <ForgetPasswordForm />
        </ThemeProvider>
    );
};
